.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  width: 117rem;
  transform: translate(-50%, -50%) !important;
  background-color: #fff;
  border-radius: 0.5rem;
}

@media (max-width: 1280px) {
  .Modal {
    width: 77rem;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(68, 67, 67, 0.9);
}
