.ModalVideo {
  position: absolute;
  justify-content: center;
  right: 30%;
  left: 30%;
  height: 70%;
  bottom: 30px;
  background-color: transparent;
  border-radius: 10px;

}
@media (max-width: 1500px) {
  .ModalVideo {
    right: 5%;
    left: 5%;
  
  }
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(68, 67, 67, 0.9);
}

.video {
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}