body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  #sessionContent {
    min-height:285px;
    width: 100%;
    /* background-color: #0F172A;
    position: relative;
    max-height: 100vh;
    min-height: 100vh;
    overflow: hidden; */
    position: relative;
  }


  #localButtonSection {
    position: absolute;
    right: 0px;
    left: 0px;
    width: 100%;
    bottom: 1.5rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }


  .localButton {
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(119, 119, 119);
    cursor: pointer;
    border: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.6rem;
    padding-left: 0.6rem;
    margin: 5px;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  #remoteSession {

    /* position: absolute;
    width: 12rem;

   right: 0px;

    /* bottom: 0px;
    padding-top: 0.2rem;
    padding-right: 0.8rem;
    overflow: auto; */
  }
  .remoteSession {
    position: absolute;
     width: 12rem;
     top:0px;
     right: 0px;
     margin: 5px;
     border-radius: 10px;
     padding-top: 0.2rem;
     padding-right: 0.2rem;
     overflow: auto;
     z-index: 999;
   }

  .localSession {
   position: absolute;
    width: 12rem;
    top:0px;
    right: 0px;
    margin: 5px;
    border-radius: 10px;
    padding-top: 0.2rem;
    padding-right: 0.2rem;
    overflow: auto;
  }


  .remoteVideo {
   /* position: absolute; */
  }




  .remoteControls {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 1px;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .remoteButton {
    border-radius: 9999px;
    display: flex;
    border: transparent;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
    background-color: transparent;
  }


  #loaderSection {
    background-color: #243342;
    overflow: hidden;
    display: flex;
    height: 285px;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .loaderSectionHeightLocal {
    height: 285px !important;
  }

  .loaderSectionHeightRemote {
    height: 85px !important;
  }

  .loaderIcon {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loaderText {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    font-size: 26px;
    margin-left: 4px;
  }

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }
